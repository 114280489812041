'use client';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginMutation } from 'helpers/api';
import { useCallback } from 'react';
import { Form } from 'components/Form';

const formSchema = z.object({
  email: z.string().email({ message: 'Voer een geldig e-mailadres in.' }),
});

export const LoginForm = () => {
  const { mutate, isPending, isError, isSuccess } = useLoginMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '' },
  });

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      mutate(values);
    },
    [mutate]
  );

  if (isSuccess) {
    return (
      <div>
        <h2>De login mail is verstuurd!</h2>
        <p>Klik op de link in je mailbox om in te loggen. Geen link ontvangen? Dubbelcheck dan je e-mailadres.</p>
      </div>
    );
  }

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      buttonLabel="Inloggen"
      isLoading={isPending}
      error={isError ? 'Er ging iets mis, probeer het nog eens' : undefined}>
      <FormField
        control={form.control}
        name="email"
        // eslint-disable-next-line react/jsx-no-bind
        render={({ field }) => (
          <FormItem>
            <FormLabel>Jouw e-mailadres</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormDescription>Je ontvangt een link in je mailbox waarmee je direct in kan loggen.</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
};
